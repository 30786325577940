import { render, staticRenderFns } from "./SidebarFluxo.vue?vue&type=template&id=4edc18dc&scoped=true&"
import script from "./SidebarFluxo.vue?vue&type=script&lang=js&"
export * from "./SidebarFluxo.vue?vue&type=script&lang=js&"
import style0 from "./SidebarFluxo.vue?vue&type=style&index=0&id=4edc18dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edc18dc",
  null
  
)

export default component.exports